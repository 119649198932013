import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>
                    Un any més arriba la tarifa plana de físiques a <strong>CEM BESÒS</strong> amb
                    una nova variant: tots els entrenaments tenen una duració de{" "}
                    <strong>dues hores</strong>.
                </p>

                <p>
                    T'imagines poder entrenar <strong>cada dia de la setmana</strong> i arribar al
                    teu màxim nivell?
                </p>

                <p>
                    Ara és possible, gràcies a la <strong>TARIFA PLANA</strong>, que ja s'ha
                    consolidat com una eina clau a l'hora d'afrontar els últims dies de cara a les
                    proves físiques oficials de la convocatòria.
                </p>

                <p>
                    Amb una única quota podràs entrenar amb nosaltres <strong>cada dia</strong>, amb
                    els <strong>millors monitors</strong>, <strong>material oficial</strong> i una{" "}
                    <strong>planificació</strong>.
                </p>

                <p>
                    A <strong>iOpos</strong> ho tenim tot planificat i no deixem res a l'atzar.
                </p>

                <p>A cada estació trobaràs un monitor de referència.</p>

                <p>
                    <strong>Pren nota:</strong>
                </p>

                <p>
                    <strong>L'inici de la TARIFA PLANA serà l'1 de març.</strong>
                </p>

                {/*<p>
                    <strong>Horaris disponibles:</strong>
                </p>

                <ul>
                    <li>
                        <strong>Dilluns:</strong> 9:30 - 11:30 h | 15:30 - 17:30 h
                    </li>
                    <li>
                        <strong>Dimarts:</strong> 9:30 - 11:30 h | 15:30 - 17:30 h
                    </li>
                    <li>
                        <strong>Dimecres:</strong> 9:30 - 11:30 h | 15:30 - 17:30 h
                    </li>
                    <li>
                        <strong>Dijous:</strong> 9:30 - 11:30 h | 15:30 - 17:30 h
                    </li>
                    <li>
                        <strong>Divendres:</strong> 9:30 - 11:30 h | 15:00 - 17:00 h
                    </li>
                    <li>
                        <strong>Dissabte:</strong> 9:00 - 11:30 h | 11:30 - 13:30 h
                    </li>
                </ul>*/}

                <p>
                    <strong>L'únic requisit</strong> és que no realitzis doble entrenament el mateix
                    dia per garantir el descans i prevenir lesions.
                </p>

                <p>
                    <strong>Preu: 89,99 €</strong>
                </p>

                <p>
                    <strong>CEM BESÒS</strong>, Av. Ramon Penyafort s/n, Sant Adrià del Besòs.
                </p>

                {/*<div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>*/}
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Entrena sense límits i prepara't per superar les teves físiques amb la TARIFA PLANA! 💪🔥"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
